import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

export default class About extends Component {
  render() {
    return (
      <Container>
        <h1>About Me</h1>
        <h5>Code for editing my about section here</h5>
      </Container>
    );
  }
}
