export const INDEX = '/';
export const LOGIN = '/login';
export const ABOUT = '/about';

export const PROJECTS = '/projects';
export const PROJECT_ADD = `${PROJECTS}/add`;
export const PROJECT_EDIT = `${PROJECTS}/:projectId`;

export const COMPANIES = '/companies';
export const COMPANY_ADD = `${COMPANIES}/add`;
export const COMPANY_EDIT = `${COMPANIES}/:companyId`;
